import React from 'react';
import Layout from '../shared/Layout';
import HeaderMain from '../shared/HeaderMain';
import Footer from '../shared/Footer';

import Presentation from '../shared/Presentation';
import PressKit from '../shared/PressKit';
import NewsBlock from '../shared/NewsBlock';
import Video from '../shared/Video';
import Certificate from '../shared/Certificate';
import Cookie from '../shared/Cookie';

const Media = () => {
  window.scroll(0, 0);
  return (
    <>
      <Layout>
        <HeaderMain />
        <PressKit />
        <Presentation />
      </Layout>
      <Certificate />
      <Layout>
        <NewsBlock />
        <Video />
      </Layout>
      <Footer />
      <Cookie />
    </>
  );
};

export default Media;
