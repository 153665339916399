import React from 'react';

import Layout from '../shared/Layout';

import Footer from '../shared/Footer';
import ContactsBlock from '../shared/ContactsBlock';
import HeaderMain from '../shared/HeaderMain';
import Cookie from '../shared/Cookie';

const ContactsPage = () => {
  window.scroll(0, 0);
  return (
    <>
      <Layout>
        <HeaderMain />
        <ContactsBlock />
      </Layout>
      <Footer />
      <Cookie />
    </>
  );
};

export default ContactsPage;
