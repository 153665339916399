import React from 'react'
import HeaderMain from '../shared/HeaderMain'
import Layout from '../shared/Layout'
import Footer from '../shared/Footer'
import Cookie from '../shared/Cookie'
import ServicesNew from '../shared/ServicesNew'

const ServiceTwo = () => {
   window.scroll(0, 0)
   return (
      <>
         <Layout>
            <HeaderMain />
            <ServicesNew />
         </Layout>
         <Footer />
         <Cookie />
      </>
   )
}

export default ServiceTwo
