import React from 'react';
import ServicesIntro from '../shared/ServicesIntro';
import Layout from '../shared/Layout';
import Application from '../shared/Application';
import Options from '../shared/Options';
import TechnicalCapabilities from '../shared/TechnicalCapabilities';
import Footer from '../shared/Footer';
import Cookie from '../shared/Cookie';

const ServiceOne = () => {
  window.scroll(0, 0);
  return (
    <>
      <Layout>
        <ServicesIntro />
        <Application />
        <TechnicalCapabilities />
        <Options />
      </Layout>
      <Footer />
      <Cookie />
    </>
  );
};

export default ServiceOne;
