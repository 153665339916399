import React from 'react';
import './industry.css';
import Layout from '../Layout';

const Industry = () => {
  return (
    <div className="industry">
      <Layout>
        <h2 className="industry__title">отрасли</h2>
        <div className="industry__content">
          <div className="industry__block first-child">
            <p className="industry__text main-text red-color">Добыча нефти и газа</p>
          </div>
          <div className="industry__block second-child">
            <p className="industry__text main-text">Металлургия</p>
          </div>
          <div className="industry__block third-child">
            <p className="industry__text middle-text">Химия и нефтехимия</p>
          </div>
          <div className="industry__block fourth-child">
            <p className="industry__text red-color">Газораспределительные сети</p>
          </div>
          <div className="industry__block fifth-child">
            <p className="industry__text red-color">Переработка</p>
          </div>
          <div className="industry__block sixth-child">
            <p className="industry__text">Транспортировка</p>
          </div>
          <div className="industry__block seventh-child">
            <p className="industry__text">Коммунальные службы</p>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Industry;
