import React from 'react'

import './servicesnew.css'

import serviceNew from '../../img/servicesnew.png'
import serviceNewMobile from '../../img/servicesnew-mobile.png'

const ServicesNew = () => {
   return (
      <div className='img-wrapper'>
         <img className='servicesnew' src={serviceNew} alt='' />
         <img className='servicesnew-mobile' src={serviceNewMobile} alt='' />
         <p class='servicesnew-text'>
            &nbsp;Более подробная информация о продукции на официальном сайте компании
            <a class='servicesnew-text-link' href=' https://www.tdwilliamson.com/resources/product-bulletins'>
               T.D.Williamson
            </a>
         </p>
      </div>
   )
}

export default ServicesNew
