import React from 'react';
import './certificate.css';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Layout from '../Layout';

import firstSlide from '../../img/cert/1.png';
import secondSlide from '../../img/cert/2.png';
import thirdSlide from '../../img/cert/3.png';
import fourthSlide from '../../img/cert/4.png';
import fifthSlide from '../../img/cert/5.png';
import sixthSlide from '../../img/cert/6.png';
import seventhSlide from '../../img/cert/7.png';
import eighthSlide from '../../img/cert/8.png';
import ninthSlide from '../../img/cert/9.png';
import tenthSlide from '../../img/cert/10.png';
import eleventhSlide from '../../img/cert/11.png';
import twelfthSlide from '../../img/cert/12.png';
import thirteenthSlide from '../../img/cert/13.png';
import fourteenthSlide from '../../img/cert/14.png';
import fifteenthSlide from '../../img/cert/15.png';
import sixteenthSlide from '../../img/cert/16.png';
import seventeenSlide from '../../img/cert/17.png';
import eighthteenSlide from '../../img/cert/18.png';

const Certificate = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 3,
    },
  };
  return (
    <div className="certificate">
      <Layout>
        <h2 className="news-block__title global-title certificate__title">Сертификаты</h2>
      </Layout>
      <Carousel responsive={responsive}>
        <div>
          <img className="certificate__img" src={firstSlide} alt="Первый слайд" />
        </div>
        <div>
          <img className="certificate__img" src={secondSlide} alt="Второй слайд" />
        </div>
        <div>
          <img className="certificate__img" src={thirdSlide} alt="Третий слайд" />
        </div>
        <div>
          <img className="certificate__img" src={fourthSlide} alt="Четвертый слайд" />
        </div>
        <div>
          <img className="certificate__img" src={fifthSlide} alt="Пятый слайд" />
        </div>
        <div>
          <img className="certificate__img" src={sixthSlide} alt="Шестой слайд" />
        </div>
        <div>
          <img className="certificate__img" src={seventhSlide} alt="Шестой слайд" />
        </div>
        <div>
          <img className="certificate__img" src={eighthSlide} alt="Шестой слайд" />
        </div>
        <div>
          <img className="certificate__img" src={ninthSlide} alt="Шестой слайд" />
        </div>
        <div>
          <img className="certificate__img" src={tenthSlide} alt="Шестой слайд" />
        </div>
        <div>
          <img className="certificate__img" src={eleventhSlide} alt="Шестой слайд" />
        </div>
        <div>
          <img className="certificate__img" src={twelfthSlide} alt="Шестой слайд" />
        </div>
        <div>
          <img className="certificate__img" src={thirteenthSlide} alt="Шестой слайд" />
        </div>
        <div>
          <img className="certificate__img" src={fourteenthSlide} alt="Шестой слайд" />
        </div>
        <div>
          <img className="certificate__img" src={fifteenthSlide} alt="Шестой слайд" />
        </div>
        <div>
          <img className="certificate__img" src={sixteenthSlide} alt="Шестой слайд" />
        </div>
        <div>
          <img className="certificate__img" src={seventeenSlide} alt="Шестой слайд" />
        </div>
        <div>
          <img className="certificate__img" src={eighthSlide} alt="Шестой слайд" />
        </div>
      </Carousel>
    </div>
  );
};

export default Certificate;
