import React from 'react'
import { Routes, Route } from 'react-router-dom'

import './css/main.global.css'
import Home from './pages/Home'
import About from './pages/About'
import ContactsPage from './pages/ContactsPage'
import News from './pages/News'
import Media from './pages/Media'
import ServicesMain from './pages/ServicesMain'
import ServiceOne from './pages/ServiceOne'
import ServiceTwo from './pages/ServiceTwo'
import NotFound from './pages/NotFound'
import NewOne from './pages/NewOne'
import NewTwo from './pages/NewTwo'
import NewThree from './pages/NewThree'

function App() {
   return (
      <div className='App'>
         <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/services' element={<ServicesMain />} />
            <Route path='/tapping-under-pressure' element={<ServiceOne />} />
            <Route path='/supply-of-equipment-and-consumables' element={<ServiceTwo />} />
            <Route path='/news' element={<News />} />
            <Route path='/media' element={<Media />} />
            <Route path='/contacts' element={<ContactsPage />} />
            <Route path='/prequalification' element={<NewOne />} />
            <Route path='/certification' element={<NewTwo />} />
            <Route path='/certification-naks' element={<NewThree />} />
            <Route path='*' element={<NotFound />} />
         </Routes>
      </div>
   )
}

export default App
