import React from 'react';
import './tagline.css';

const Tagline = () => {
  return (
    <div className="tagline">
      <h2 className="tagline__title">
        <p> производство и выполнение</p>
        <p> работ по врезкам и</p>
        <p>перекрытию трубопровода</p>
        <p>под давлением</p>
        <p>на территории России и СНГ</p>
      </h2>
    </div>
  );
};

export default Tagline;
