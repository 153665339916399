import React from 'react';
import './technicalcapabilities.css';

import technicalImg from '../../img/technical.png';
import technicalImgMobile from '../../img/technical-img-mobile.png';

const TechnicalCapabilities = () => {
  return (
    <div className="technical-capabilities">
      <h2 className="global-title services-page__title technical-capabilities__title">
        Технические возможности оборудования:
      </h2>
      <img className="technical-img" src={technicalImg} alt="Изображение" />
      <img className="technical-img-mobile" src={technicalImgMobile} alt="Изображение" />
    </div>
  );
};

export default TechnicalCapabilities;
