import React from 'react';
import './presskit.css';

import laptop from '../../img/laptop.png';

const PressKit = () => {
  return (
    <div className="press-kit">
      <h2 className="press-kit__title">пресс-кит</h2>
      <p className="press-kit__text">
        В данном разделе Вы можете ознакомиться с рекламными материалами нашей компании для сбора
        информации о нас или для упоминания нашей компании в ваших публикациях.
      </p>

      <img className="press-kit__laptop" src={laptop} alt="Ноутбук" />
    </div>
  );
};

export default PressKit;
