import React from 'react';
import './new.css';
import { Link } from 'react-router-dom';

const New = ({ title, day, month, year, link, imgSrc }) => {
  return (
    <Link to={link} className="new">
      <img src={imgSrc} alt='Обложка'/>
      <h2 className="new__title">{title}</h2>
      <div className="new__date">
        <span className="new__day">{day}</span>
        <div className="new__date-block">
          <span className="new__month">{month}</span>
          <span className="new__year">{year}</span>
        </div>
      </div>
    </Link>
  );
};

export default New;
