import React from 'react'
import './newsblock.css'
import New from '../New'

import { Link } from 'react-router-dom'
import Layout from '../Layout'

import new3 from '../../img/news-back-three-1.webp'
import new2 from '../../img/newintro.png'
import new1 from '../../img/news-back-one.png'

const NewsBlock = () => {
   return (
      <div className='news-block'>
         <Layout>
            <h2 className='news-block__title global-title'>Новости</h2>
         </Layout>
         <Layout>
            <div className='news-block__content'>
               <New
                  title='Успешная аттестация по требованиям НАКС'
                  day='27'
                  month='ноября'
                  year='2024'
                  imgSrc={new3}
                  link='/certification-naks'
               />
               <New
                  title='Предквалификация по требованиям «Газпром»'
                  day='07'
                  month='июня'
                  year='2024'
                  imgSrc={new2}
                  link='/prequalification'
               />
               <New title='Aттестация' day='25' month='марта' year='2024' imgSrc={new1} link='/certification' />
               {/* <Link to="/certification" className="new news-back">
            <h2 className="new__title">Aттестация</h2>
            <div className="new__date">
              <span className="new__day">25</span>
              <div className="new__date-block">
                <span className="new__month">март</span>
                <span className="new__year">2024</span>
              </div>
            </div>
          </Link> */}
            </div>
         </Layout>
         <Layout>
            <Link className='news-block__button' to='/news'>
               Показать все
            </Link>
         </Layout>
      </div>
   )
}

export default NewsBlock
