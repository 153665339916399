import React from 'react';
import './about.css';
import Layout from '../Layout';

const About = () => {
  return (
    <div className="about">
      <Layout>
        <div className="about__content">
          <h2 className="about__title">
            <p>Инженерная Компания</p>
            <p>«Современные</p>
            <p>Технологические Решения»</p>
          </h2>
          <div className="about__text">
            Компания ООО ИК «СРТ» была создана в&nbsp;рамках мер по автономизации нефтегазовой
            отрасли от&nbsp;зарубежных технологий и&nbsp;обеспечения развития российского
            технологического суверенитета
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default About;
