import React from 'react';
import './aboutus.css';

const AboutUs = () => {
  return (
    <div className="about-us">
      <h2 className="about-us__title">
        Концептуальный подход ООО ИК «СТР» – оказание услуг полного цикла:
      </h2>
      <div className="about-us__content">
        <div className="about-us__block">
          <p className="about-us__text first-text-item">
            От разработки проектной документации и выполнения комплекса работ по врезки и перекрытию
            трубопровода под давлением
          </p>
        </div>
        <div className="about-us__block">
          <p className="about-us__text">
            До организации на территории России собственного производства разрезных тройников и
            фитингов, а также выпуск необходимых расходных материалов для специализированного
            оборудования
          </p>
        </div>
        <div className="about-us__img">
          <svg
            width="298"
            height="320"
            viewBox="0 0 298 320"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M254.57 204.574L254.57 204.574L142.4 316.664C140.006 319.056 136.112 319.056 133.718 316.664L93.632 276.607L93.63 276.605C91.2235 274.213 91.2235 270.326 93.63 267.935L93.632 267.933L165.296 196.32L167.004 194.613H164.589H7.99265C4.59461 194.613 1.85272 191.859 1.85272 188.478V131.819C1.85272 128.438 4.59461 125.684 7.99265 125.684H164.527H166.942L165.233 123.977L93.632 52.4267L93.63 52.4248C91.2235 50.0334 91.2235 46.1466 93.63 43.7552L93.632 43.7532L133.718 3.69555C136.112 1.30331 140.006 1.30331 142.4 3.69555L254.57 115.785L254.57 115.786L294.654 155.826C294.654 155.826 294.655 155.826 294.655 155.826C297.05 158.234 297.05 162.126 294.655 164.534C294.655 164.534 294.654 164.534 294.654 164.534L254.57 204.574Z"
              fill="#F3F3F3"
              stroke="#FF0000"
              stroke-width="2"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
