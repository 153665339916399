import React from 'react'
import './newsmain.css'

import firstImg from '../../img/news-back-one.png'
import secondImg from '../../img/news-back-two.png'
import thirdImg from '../../img/news-back-three-1.webp'
import { Link } from 'react-router-dom'

const NewsMain = () => {
   return (
      <div className='news-main-content'>
         <div className='news-main-item'>
            <h2 className='news-main-title'>Новости</h2>
            <div className='news-main-block'>
               <p className='news-main-text'>
                  В марте 2024г., компания ООО ИК «СТР» успешно завершила процедуру прохождения предквалификации по требованиям
                  ПАО «Газпром» и вошла в Реестр потенциальных участников закупок Группы ПАО «Газпром».
               </p>
            </div>
         </div>
         <div className='news-main-dual'>
            <div className='news-main-content-block'>
               <div className='news-main-cover-wrapper'>
                  <img src={thirdImg} alt='Изображение новости' />
               </div>
               <div className='news-main-content-block-text'>
                  <h2 className='news-main-content-title'>Успешная аттестация по требованиям НАКС </h2>
                  <p className='news-main-content-text'>
                     На производственной площадке в г. Екатеринбурге компания ООО ИК «СТР» с 05 по 08 ноября 2024 года успешно проведена
                     периодическая аттестация сварщиков и проверка готовности к выполнению сварочных работ в соответствии с требованиями
                     «Положения об аттестации на объектах ПАО «Газпром».
                  </p>
                  <div className='bottom-news-block'>
                     <p className='news-main-content-text'>27 ноября 2024</p>
                     <Link to='/certification-naks' className='news-main-content-more-text'>
                        Подробнее
                     </Link>
                  </div>
               </div>
            </div>

            <div className='news-main-content-block'>
               <div className='news-main-cover-wrapper'>
                  <img src={firstImg} alt='Изображение новости' />
               </div>
               <div className='news-main-content-block-text'>
                  <h2 className='news-main-content-title'>Aттестация</h2>
                  <p className='news-main-content-text'>
                     В мае 2023 года в рамках реализации долгосрочной программы развития компании ООО ИК «Современные Технологические
                     Решения» выполнена аттестация сварщиков и технологии сварки
                  </p>
                  <div className='bottom-news-block'>
                     <p className='news-main-content-text'>25 марта 2024</p>
                     <Link to='/certification' className='news-main-content-more-text'>
                        Подробнее
                     </Link>
                  </div>
               </div>
            </div>
         </div>

         <div className='news-main-triple'>
           <div className='news-main-content-block'>
               <div className='news-main-cover-wrapper'>
                  <img src={secondImg} alt='Изображение новости' />
               </div>
               <div className='news-main-content-block-text'>
                  <h2 className='news-main-content-title'>Предквалификация </h2>
                  <p className='news-main-content-text'>
                     В марте 2024г., компания ООО ИК «СТР» успешно завершила процедуру прохождения предквалификации по требованиям ПАО
                     «Газпром» и вошла в Реестр потенциальных участников закупок Группы ПАО «Газпром»
                  </p>
                  <div className='bottom-news-block'>
                     <p className='news-main-content-text'>07 июня 2024</p>
                     <Link to='/prequalification' className='news-main-content-more-text'>
                        Подробнее
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default NewsMain
