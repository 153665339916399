import React from 'react';
import './headermain.css';

import { Link } from 'react-router-dom';

import Form from '../Form';

const HeaderMain = () => {
  const [modalActive, setModalActive] = React.useState(false);
  const [formActive, setFormActive] = React.useState(false);

  return (
    <>
      <header className="header header-main">
        <Link className="logo header-logo" to="/">
          <svg
            width="248"
            height="93"
            viewBox="0 0 248 93"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M108.799 52.8876C108.158 51.961 107.837 50.679 107.837 49.0468V43.484C107.837 41.7603 108.153 40.4508 108.779 39.5553C109.406 38.6598 110.519 38.2129 112.116 38.2129C113.542 38.2129 114.566 38.5959 115.188 39.3603C115.809 40.1264 116.119 41.2531 116.119 42.7438V43.9879H114.019V42.8801C114.019 42.1537 113.986 41.5964 113.922 41.2065C113.856 40.8183 113.688 40.506 113.42 40.273C113.152 40.0401 112.724 39.9228 112.135 39.9228C111.547 39.9228 111.085 40.0487 110.791 40.3024C110.496 40.556 110.303 40.9063 110.213 41.3531C110.122 41.8 110.075 42.4005 110.075 43.1528V49.3953C110.075 50.1994 110.14 50.8274 110.272 51.2812C110.402 51.735 110.615 52.0594 110.909 52.2543C111.204 52.4493 111.613 52.5459 112.135 52.5459C112.698 52.5459 113.117 52.4234 113.391 52.1767C113.666 51.93 113.838 51.5935 113.91 51.1656C113.981 50.7377 114.018 50.1545 114.018 49.4143V48.2479H116.118V49.4143C116.118 50.9827 115.82 52.1853 115.224 53.0221C114.629 53.859 113.593 54.2765 112.113 54.2765C110.542 54.2765 109.436 53.8124 108.795 52.8858L108.799 52.8876Z"
              fill="#E30B00"
            />
            <path
              d="M118.719 52.9652C118.045 52.0904 117.709 50.8222 117.709 49.1641V43.1924C117.709 41.5585 118.049 40.3214 118.729 39.4776C119.41 38.6356 120.523 38.2129 122.066 38.2129C123.609 38.2129 124.718 38.6373 125.392 39.4862C126.066 40.3352 126.402 41.5706 126.402 43.1907V49.1814C126.402 50.8153 126.062 52.0732 125.382 52.9549C124.701 53.8365 123.595 54.2782 122.064 54.2782C120.534 54.2782 119.391 53.84 118.717 52.9652H118.719ZM123.734 51.8282C124.022 51.3485 124.166 50.6221 124.166 49.6507V42.7473C124.166 41.788 124.022 41.0788 123.734 40.6181C123.447 40.1574 122.89 39.928 122.066 39.928C121.242 39.928 120.666 40.1592 120.377 40.6181C120.088 41.0771 119.945 41.788 119.945 42.7473V49.6507C119.945 50.6238 120.088 51.3485 120.377 51.8282C120.666 52.3078 121.227 52.5477 122.066 52.5477C122.905 52.5477 123.447 52.3078 123.734 51.8282Z"
              fill="black"
            />
            <path
              d="M128.347 38.3508H131.999C133.439 38.3508 134.505 38.6718 135.198 39.3136C135.891 39.9555 136.237 40.951 136.237 42.2986C136.237 44.3087 135.478 45.4164 133.961 45.6252C134.904 45.846 135.59 46.2826 136.021 46.9382C136.453 47.5939 136.669 48.4842 136.669 49.6126C136.669 51.0257 136.331 52.1283 135.659 52.9185C134.985 53.7088 133.961 54.1056 132.587 54.1056H128.347V38.3526V38.3508ZM131.88 44.9247C132.796 44.9247 133.43 44.7262 133.784 44.3311C134.137 43.936 134.313 43.2579 134.313 42.2986C134.313 41.5981 134.205 41.0839 133.989 40.7526C133.773 40.4231 133.449 40.2126 133.018 40.1211C132.586 40.0314 131.945 39.9848 131.093 39.9848H130.564V44.9247H131.879H131.88ZM132.056 52.5286C132.711 52.5286 133.218 52.4285 133.577 52.2266C133.937 52.0265 134.188 51.7176 134.332 51.3035C134.475 50.8894 134.548 50.3252 134.548 49.6126C134.548 48.6015 134.339 47.8458 133.92 47.3472C133.5 46.8485 132.821 46.5983 131.879 46.5983H130.564V52.5303H132.056V52.5286Z"
              fill="black"
            />
            <path
              d="M138.397 38.3508H142.343C143.717 38.3508 144.715 38.7201 145.336 39.4585C145.958 40.197 146.268 41.2806 146.268 42.7058C146.268 43.9895 145.935 44.9885 145.267 45.7011C144.6 46.4137 143.632 46.7709 142.362 46.7709H140.616V54.1039H138.397V38.3508ZM141.636 45.1973C142.291 45.1973 142.797 45.1265 143.156 44.9833C143.515 44.8401 143.774 44.5934 143.931 44.2449C144.088 43.8946 144.166 43.389 144.166 42.7282C144.166 41.9242 144.107 41.3341 143.99 40.9579C143.872 40.5818 143.64 40.3161 143.294 40.1608C142.947 40.0055 142.4 39.9279 141.655 39.9279H140.616V45.199H141.636V45.1973Z"
              fill="black"
            />
            <path
              d="M147.583 38.3508H153.747V39.9848H149.801V45.1973H153V46.7536H149.801V52.5303H153.785V54.1056H147.583V38.3526V38.3508Z"
              fill="black"
            />
            <path
              d="M155.591 38.3508H157.77L160.577 51.1275L163.403 38.3508H165.563L165.857 54.1039H164.07L163.894 42.6678L161.206 54.1039H159.95L157.281 42.6678L157.105 54.1039H155.299L155.593 38.3508H155.591Z"
              fill="black"
            />
            <path
              d="M168.056 38.3508H174.221V39.9848H170.275V45.1973H173.474V46.7536H170.275V52.5303H174.259V54.1056H168.056V38.3526V38.3508Z"
              fill="black"
            />
            <path
              d="M175.809 38.3508H178.028V45.1576H182.109V38.3508H184.328V54.1039H182.109V46.7329H178.028V54.1039H175.809V38.3508Z"
              fill="black"
            />
            <path
              d="M186.584 38.3508H188.803V45.1576H192.885V38.3508H195.103V54.1039H192.885V46.7329H188.803V54.1039H186.584V38.3508Z"
              fill="black"
            />
            <path
              d="M197.36 38.3508H199.579V44.9057H200.206C201.409 44.9057 202.376 45.042 203.102 45.3146C203.828 45.5872 204.375 46.0548 204.74 46.7157C205.106 47.3765 205.291 48.303 205.291 49.497C205.291 51.1189 204.892 52.2905 204.093 53.0169C203.295 53.7433 202.031 54.1056 200.305 54.1056H197.36V38.3526V38.3508ZM200.305 52.5286C201.012 52.5286 201.571 52.4509 201.984 52.2957C202.396 52.1404 202.703 51.8419 202.907 51.4002C203.109 50.9602 203.211 50.3183 203.211 49.4746C203.211 48.7482 203.119 48.1719 202.936 47.744C202.753 47.3161 202.449 47.0021 202.024 46.8002C201.599 46.6 201.014 46.4982 200.267 46.4982H199.581V52.5269H200.307L200.305 52.5286ZM206.94 38.3508H209.158V54.1039H206.94V38.3508Z"
              fill="black"
            />
            <path
              d="M211.414 38.3508H217.578V39.9848H213.632V45.1973H216.831V46.7536H213.632V52.5303H217.616V54.1056H211.414V38.3526V38.3508Z"
              fill="black"
            />
            <path
              d="M109.878 59.4321H107.189V57.7981H114.707V59.4321H112.097V73.5512H109.878V59.4321Z"
              fill="#E30B00"
            />
            <path
              d="M116.001 57.7981H122.165V59.4321H118.22V64.6446H121.418V66.2009H118.22V71.9776H122.204V73.5529H116.001V57.7998V57.7981Z"
              fill="black"
            />
            <path
              d="M126.091 65.3054L122.832 57.7981H124.894L127.308 63.3608L129.505 57.7981H131.567L128.231 65.4797L131.724 73.5512H129.664L127.073 67.6192L124.835 73.5512H122.775L126.092 65.3054H126.091Z"
              fill="black"
            />
            <path
              d="M132.979 57.7981H135.198V64.6049H139.28V57.7981H141.498V73.5512H139.28V66.1802H135.198V73.5512H132.979V57.7981Z"
              fill="black"
            />
            <path
              d="M144.472 72.4142C143.798 71.5394 143.462 70.2712 143.462 68.6131V62.6414C143.462 61.0074 143.802 59.7703 144.483 58.9266C145.164 58.0846 146.276 57.6619 147.819 57.6619C149.362 57.6619 150.471 58.0863 151.145 58.9352C151.819 59.7841 152.155 61.0195 152.155 62.6397V68.6303C152.155 70.2643 151.816 71.5221 151.135 72.4038C150.454 73.2855 149.348 73.7272 147.818 73.7272C146.287 73.7272 145.145 73.289 144.471 72.4142H144.472ZM149.488 71.2771C149.775 70.7975 149.919 70.0711 149.919 69.0997V62.1963C149.919 61.2369 149.775 60.5278 149.488 60.0671C149.2 59.6064 148.643 59.3769 147.819 59.3769C146.996 59.3769 146.419 59.6081 146.13 60.0671C145.841 60.5261 145.698 61.2369 145.698 62.1963V69.0997C145.698 70.0728 145.841 70.7975 146.13 71.2771C146.419 71.7568 146.98 71.9966 147.819 71.9966C148.659 71.9966 149.2 71.7568 149.488 71.2771Z"
              fill="black"
            />
            <path
              d="M152.903 71.9172C153.465 71.9293 153.904 71.8257 154.218 71.6066C154.531 71.3858 154.794 70.8681 155.003 70.0503C155.212 69.2342 155.376 67.9435 155.494 66.1802L156.023 57.7981H163.012V73.5512H160.793V59.4321H157.927L157.554 66.1802C157.45 67.9435 157.272 69.3446 157.025 70.3816C156.776 71.4185 156.37 72.2105 155.807 72.754C155.245 73.2992 154.446 73.5701 153.413 73.5701C153.19 73.5701 153.021 73.5632 152.903 73.5512V71.9172Z"
              fill="black"
            />
            <path
              d="M165.986 72.4142C165.312 71.5394 164.976 70.2712 164.976 68.6131V62.6414C164.976 61.0074 165.315 59.7703 165.996 58.9266C166.677 58.0846 167.79 57.6619 169.333 57.6619C170.875 57.6619 171.985 58.0863 172.659 58.9352C173.333 59.7841 173.669 61.0195 173.669 62.6397V68.6303C173.669 70.2643 173.329 71.5221 172.648 72.4038C171.967 73.2855 170.861 73.7272 169.331 73.7272C167.8 73.7272 166.658 73.289 165.984 72.4142H165.986ZM171.001 71.2771C171.288 70.7975 171.433 70.0711 171.433 69.0997V62.1963C171.433 61.2369 171.288 60.5278 171.001 60.0671C170.713 59.6064 170.156 59.3769 169.333 59.3769C168.509 59.3769 167.932 59.6081 167.643 60.0671C167.354 60.5261 167.212 61.2369 167.212 62.1963V69.0997C167.212 70.0728 167.354 70.7975 167.643 71.2771C167.932 71.7568 168.493 71.9966 169.333 71.9966C170.172 71.9966 170.713 71.7568 171.001 71.2771Z"
              fill="black"
            />
            <path
              d="M175.612 57.7981H181.658V59.4321H177.831V73.5512H175.612V57.7981Z"
              fill="black"
            />
            <path
              d="M182.952 57.7981H184.914V68.7079L189.566 57.7981H191.156V73.5512H189.212V62.4653L184.599 73.5512H182.95V57.7981H182.952Z"
              fill="black"
            />
            <path
              d="M199.5 66.2785C198.637 66.4735 197.858 66.5701 197.165 66.5701C195.803 66.5701 194.793 66.2682 194.133 65.666C193.473 65.0638 193.143 64.1459 193.143 62.9139V57.7998H195.38V62.9139C195.38 63.68 195.549 64.2391 195.89 64.5962C196.232 64.9534 196.799 65.1311 197.599 65.1311C198.161 65.1311 198.797 65.0535 199.502 64.8982V57.7998H201.74V73.5529H199.502V66.2785H199.5Z"
              fill="black"
            />
            <path
              d="M203.974 57.7981H210.139V59.4321H206.193V64.6446H209.392V66.2009H206.193V71.9776H210.177V73.5529H203.974V57.7998V57.7981Z"
              fill="black"
            />
            <path
              d="M212.395 72.3365C211.754 71.41 211.434 70.128 211.434 68.4958V62.933C211.434 61.2093 211.749 59.8997 212.376 59.0042C213.003 58.1087 214.116 57.6619 215.713 57.6619C217.139 57.6619 218.163 58.0449 218.784 58.8093C219.406 59.5754 219.716 60.7021 219.716 62.1928V63.4368H217.616V62.3291C217.616 61.6027 217.583 61.0454 217.518 60.6555C217.452 60.2672 217.285 59.9549 217.017 59.722C216.749 59.4891 216.32 59.3718 215.732 59.3718C215.143 59.3718 214.682 59.4977 214.387 59.7513C214.093 60.005 213.9 60.3552 213.809 60.8021C213.719 61.249 213.672 61.8495 213.672 62.6017V68.8443C213.672 69.6483 213.736 70.2764 213.869 70.7302C213.999 71.184 214.212 71.5083 214.506 71.7033C214.8 71.8983 215.209 71.9949 215.732 71.9949C216.294 71.9949 216.714 71.8724 216.987 71.6257C217.263 71.3789 217.435 71.0425 217.506 70.6146C217.578 70.1867 217.614 69.6035 217.614 68.8633V67.6969H219.714V68.8633C219.714 70.4317 219.417 71.6343 218.821 72.4711C218.225 73.3079 217.189 73.7255 215.709 73.7255C214.138 73.7255 213.033 73.2614 212.392 72.3348L212.395 72.3365Z"
              fill="black"
            />
            <path
              d="M221.6 57.7981H223.819V64.7999H224.643L227.488 57.7981H229.707L226.567 65.2847L230.1 73.5494H227.882L224.662 65.8679H223.817V73.5494H221.599V57.7964L221.6 57.7981Z"
              fill="black"
            />
            <path
              d="M231.337 57.7981H233.299V68.7079L237.95 57.7981H239.54V73.5512H237.597V62.4653L232.984 73.5512H231.335V57.7981H231.337Z"
              fill="black"
            />
            <path
              d="M241.797 57.7981H247.962V59.4321H244.016V64.6446H247.215V66.2009H244.016V71.9776H248V73.5529H241.797V57.7998V57.7981Z"
              fill="black"
            />
            <path
              d="M108.13 77.2471H112.076C113.45 77.2471 114.448 77.6163 115.069 78.3548C115.691 79.0933 116.001 80.1768 116.001 81.602C116.001 82.8857 115.668 83.8847 115 84.5973C114.333 85.3099 113.365 85.6671 112.095 85.6671H110.348V93.0001H108.13V77.2471ZM111.369 84.0935C112.024 84.0935 112.53 84.0228 112.889 83.8796C113.248 83.7364 113.507 83.4896 113.664 83.1411C113.821 82.7908 113.899 82.2853 113.899 81.6245C113.899 80.8204 113.84 80.2303 113.723 79.8542C113.605 79.478 113.373 79.2123 113.027 79.057C112.68 78.9017 112.133 78.8241 111.388 78.8241H110.348V84.0953H111.369V84.0935Z"
              fill="#E30B00"
            />
            <path
              d="M117.315 77.2471H123.48V78.881H119.534V84.0935H122.733V85.6498H119.534V91.4265H123.518V93.0019H117.315V77.2488V77.2471Z"
              fill="black"
            />
            <path
              d="M125.07 77.2471H127.288V91.4058H130.155V77.2471H132.373V91.4058H135.102V77.2471H137.321V93.0001H125.072V77.2471H125.07Z"
              fill="black"
            />
            <path
              d="M139.555 77.2471H145.72V78.881H141.774V84.0935H144.973V85.6498H141.774V91.4265H145.758V93.0019H139.555V77.2488V77.2471Z"
              fill="black"
            />
            <path
              d="M147.308 77.2471H149.526V84.0538H153.608V77.2471H155.827V93.0001H153.608V85.6291H149.526V93.0001H147.308V77.2471Z"
              fill="black"
            />
            <path
              d="M158.083 77.2471H160.046V88.1569L164.697 77.2471H166.287V93.0001H164.344V81.9143L159.731 93.0001H158.082V77.2471H158.083Z"
              fill="black"
            />
            <path
              d="M170.902 85.9207C170.169 85.5964 169.597 85.0718 169.185 84.3454C168.772 83.619 168.566 82.7046 168.566 81.6037C168.566 78.6999 170.228 77.2471 173.552 77.2471H176.772V93.0001H174.553V86.466H173.298L172.788 86.447L170.04 93.0019H167.567L170.903 85.9225L170.902 85.9207ZM174.553 84.8907V78.8224H173.67C172.65 78.8224 171.894 79.0398 171.403 79.4746C170.912 79.9094 170.667 80.6134 170.667 81.5848C170.667 82.7253 170.902 83.5621 171.374 84.0935C171.845 84.625 172.629 84.8907 173.73 84.8907H174.553Z"
              fill="black"
            />
            <path
              d="M107.966 0.0344238H109.52V8.67186L113.204 0.0344238H114.463V12.5057H112.926V3.73027L109.274 12.5057H107.97V0.0344238H107.966Z"
              fill="#EB0501"
            />
            <path
              d="M116.249 0.0344238H118.004V5.4229H121.236V0.0344238H122.991V12.5057H121.236V6.67038H118.004V12.5057H116.249V0.0344238Z"
              fill="black"
            />
            <path
              d="M126.784 6.13205L124.158 0.0344238H125.852L128.214 6.0544H128.308V0.0344238H129.8V6.0544H129.91L132.254 0.0344238H133.964L131.322 6.13205L134.258 12.5057H132.534L129.924 6.27008H129.798V12.5057H128.306V6.27008H128.196L125.57 12.5057H123.862L126.782 6.13205H126.784Z"
              fill="black"
            />
            <path
              d="M135.097 0.0344238H139.977V1.32676H136.854V5.45396H139.386V6.68591H136.854V11.2583H140.008V12.5057H135.097V0.0344238Z"
              fill="black"
            />
            <path
              d="M141.235 0.0344238H142.991V5.4229H146.223V0.0344238H147.978V12.5057H146.223V6.67038H142.991V12.5057H141.235V0.0344238Z"
              fill="black"
            />
            <path
              d="M149.767 0.0344238H154.646V1.32676H151.524V5.45396H154.056V6.68591H151.524V11.2583H154.677V12.5057H149.767V0.0344238Z"
              fill="black"
            />
            <path
              d="M155.903 0.0344238H159.027C160.116 0.0344238 160.905 0.327745 161.397 0.912661C161.888 1.49758 162.136 2.35511 162.136 3.48353C162.136 4.4998 161.871 5.29005 161.343 5.85426C160.816 6.41847 160.048 6.70144 159.043 6.70144H157.66V12.5057H155.905V0.0344238H155.903ZM158.468 5.45396C158.986 5.45396 159.388 5.39702 159.672 5.28487C159.957 5.17272 160.161 4.97774 160.286 4.69995C160.412 4.42216 160.473 4.02186 160.473 3.49906C160.473 2.86238 160.426 2.39479 160.333 2.09802C160.239 1.79953 160.057 1.59075 159.781 1.46652C159.506 1.34402 159.074 1.2819 158.484 1.2819H157.66V5.45396H158.468Z"
              fill="black"
            />
            <path
              d="M163.175 0.0344238H164.931V5.4229H168.163V0.0344238H169.918V12.5057H168.163V6.67038H164.931V12.5057H163.175V0.0344238Z"
              fill="black"
            />
            <path
              d="M173.695 0.0344238H175.39L178.078 12.5057H176.415L175.841 9.36376H173.277L172.671 12.5057H171.024L173.695 0.0344238ZM175.606 8.118L174.548 2.49832L173.507 8.118H175.606Z"
              fill="black"
            />
            <path
              d="M181.03 6.90158C180.45 6.64622 179.997 6.2304 179.67 5.65411C179.344 5.07954 179.18 4.35659 179.18 3.48353C179.18 1.18528 180.495 0.0344238 183.128 0.0344238H185.678V12.5057H183.922V7.33294H182.928L182.524 7.31741L180.349 12.5057H178.392L181.033 6.90158H181.03ZM183.921 6.08546V1.2819H183.22C182.412 1.2819 181.813 1.45444 181.425 1.7978C181.037 2.14116 180.842 2.69847 180.842 3.468C180.842 4.37212 181.028 5.03296 181.402 5.45396C181.775 5.87496 182.397 6.08546 183.267 6.08546H183.921Z"
              fill="black"
            />
            <path
              d="M107.966 16.6467H109.722V22.1888H110.375L112.628 16.6467H114.383L111.897 22.5753L114.693 29.118H112.938L110.39 23.0359H109.722V29.118H107.966V16.6467Z"
              fill="#E30B00"
            />
            <path
              d="M116.241 28.2174C115.707 27.5238 115.44 26.5213 115.44 25.2066V20.4806C115.44 19.1866 115.71 18.2065 116.248 17.5405C116.787 16.8728 117.668 16.5398 118.89 16.5398C120.113 16.5398 120.99 16.8763 121.523 17.5492C122.056 18.2221 122.324 19.1987 122.324 20.4824V25.2238C122.324 26.5162 122.054 27.5134 121.516 28.2105C120.978 28.9076 120.102 29.2578 118.89 29.2578C117.678 29.2578 116.774 28.911 116.241 28.2191V28.2174ZM120.212 27.3168C120.44 26.9372 120.553 26.3626 120.553 25.5931V20.1269C120.553 19.3678 120.44 18.8053 120.212 18.4412C119.984 18.0771 119.543 17.8942 118.89 17.8942C118.237 17.8942 117.781 18.0771 117.553 18.4412C117.325 18.8053 117.211 19.3678 117.211 20.1269V25.5931C117.211 26.3626 117.326 26.9372 117.553 27.3168C117.779 27.6963 118.227 27.8861 118.89 27.8861C119.554 27.8861 119.982 27.6963 120.212 27.3168Z"
              fill="black"
            />
            <path
              d="M124.065 16.6467H125.789L128.011 26.7628L130.249 16.6467H131.959L132.192 29.118H130.778L130.639 20.0648L128.509 29.118H127.515L125.401 20.0648L125.262 29.118H123.832L124.065 16.6467Z"
              fill="black"
            />
            <path
              d="M133.932 16.6467H140.863V29.118H139.108V17.8942H135.689V29.118H133.934V16.6467H133.932Z"
              fill="black"
            />
            <path
              d="M144.622 16.6467H146.317L149.006 29.118H147.343L146.768 25.9761H144.205L143.599 29.118H141.951L144.624 16.6467H144.622ZM146.533 24.7303L145.476 19.1106L144.434 24.7303H146.533Z"
              fill="black"
            />
            <path
              d="M150.092 16.6467H151.848V22.0352H155.08V16.6467H156.835V29.118H155.08V23.2827H151.848V29.118H150.092V16.6467Z"
              fill="black"
            />
            <path
              d="M158.624 16.6467H160.177V25.2842L163.86 16.6467H165.119V29.118H163.581V20.3426L159.93 29.118H158.625V16.6467H158.624Z"
              fill="black"
            />
            <path
              d="M168.771 23.5139C168.191 23.2585 167.738 22.8427 167.41 22.2664C167.085 21.6918 166.921 20.9689 166.921 20.0958C166.921 17.7976 168.236 16.6467 170.869 16.6467H173.418V29.118H171.663V23.9452H170.669L170.265 23.9297L168.09 29.118H166.132L168.774 23.5139H168.771ZM171.661 22.6978V17.8942H170.961C170.153 17.8942 169.554 18.0667 169.166 18.4101C168.777 18.7535 168.582 19.3108 168.582 20.0803C168.582 20.9844 168.769 21.6453 169.143 22.0663C169.516 22.4873 170.138 22.6978 171.008 22.6978H171.661Z"
              fill="black"
            />
            <path
              d="M93.14 38.337H66.3783L50.9443 49.7524H56.4315V84.2124H70.1258L70.1519 80.0525L70.1763 76.1306L70.2633 62.4653L70.3434 49.7524H93.746C93.8226 48.6775 93.8609 47.5939 93.8609 46.5C93.8609 43.7152 93.6136 40.9873 93.14 38.337ZM46.9304 0C21.0116 0 0 20.8189 0 46.5C0 72.1811 21.0116 93 46.9304 93C48.7293 93 50.5055 92.8999 52.2521 92.7032C53.7045 92.5428 55.1376 92.3133 56.5464 92.0234C60.2677 91.2556 63.0627 89.7528 66.4027 88.1688L70.1171 86.0224L56.5447 86.0569C55.2473 86.3658 53.9273 86.6142 52.5847 86.7971C52.4733 86.8126 52.3618 86.8265 52.2504 86.842C50.5073 87.0646 48.7328 87.1802 46.9287 87.1802C24.2541 87.1802 5.87197 68.9684 5.87197 46.5C5.87197 24.0316 24.2558 5.82156 46.9304 5.82156C60.9591 5.82156 72.9573 12.247 80.3616 22.8842L85.2027 19.5818C76.696 7.7316 62.7248 0 46.9304 0ZM46.9304 8.97217C26.0129 8.97217 9.05523 25.7743 9.05523 46.5C9.05523 67.2257 26.0129 84.0278 46.9304 84.0278C48.7363 84.0278 50.5125 83.9019 52.2521 83.6603V74.0998C50.5282 74.4241 48.7502 74.5932 46.9304 74.5932C31.2719 74.5932 18.5789 62.0167 18.5789 46.5C18.5789 34.3427 26.3716 23.9902 37.2779 20.0787C37.3772 20.0424 37.4782 20.0079 37.5792 19.9717C40.5065 18.9589 43.6532 18.4085 46.9304 18.4085C57.0427 18.4085 62.8137 19.4265 67.8341 27.3203L75.7522 22.0457C67.6164 13.491 58.2773 8.97217 46.9304 8.97217ZM73.5963 52.3457V84.2124H74.3904C77.807 81.7675 80.8754 78.8775 83.5136 75.6285V68.7096H88.1718C90.9075 63.7335 92.7516 58.2104 93.4935 52.3457H73.5963ZM81.194 62.4601V57.2666H89.0233V62.4601H81.194Z"
              fill="#E30B00"
            />
            <path
              d="M75.7521 22.0457L67.8166 27.5083C62.8606 22.1423 55.714 18.4085 46.9304 18.4085C43.6531 18.4085 40.5064 18.9589 37.5791 19.9717C37.4781 20.0079 37.3771 20.0424 37.2779 20.0787C26.3716 23.9902 18.5788 34.3427 18.5788 46.5C18.5788 62.0167 31.2718 74.5932 46.9304 74.5932C48.7502 74.5932 50.5281 74.4242 52.2521 74.0998V83.6603C50.5124 83.9019 48.7362 84.0278 46.9304 84.0278C26.0128 84.0278 9.05518 67.2274 9.05518 46.5C9.05518 25.7726 26.0128 8.97217 46.9304 8.97217C58.6587 8.97217 68.858 13.8309 75.7521 22.0457Z"
              fill="black"
            />
            <path
              d="M77.8331 24.7736L69.9168 30.0482C69.8854 30.0068 69.8558 29.9654 69.8228 29.9239L77.6608 24.5286C77.7182 24.6097 77.7757 24.6908 77.8331 24.7736Z"
              fill="black"
            />
            <path
              d="M93.8607 46.5001C93.8607 47.594 93.8224 48.6776 93.7458 49.7525H70.3432L70.2631 62.4654L70.1761 76.1307L70.1517 80.0526L70.1256 84.2126H56.4313V49.7525H41.1924L57.8453 38.3372H93.1398C93.6134 40.9874 93.8607 43.7153 93.8607 46.5001Z"
              fill="#E30B00"
            />
            <path
              d="M91.193 30.5622L72.8352 36.2664H59.9629L78.3607 23.8434L85.1486 19.3901C87.6144 22.8168 89.6553 26.5661 91.193 30.5622Z"
              fill="#E30B00"
            />
          </svg>
        </Link>
        <Link to="/" className="mobile-logo">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_382_777)">
              <path
                d="M16 0C7.16349 0 0 7.16349 0 16C0 24.8365 7.16349 32 16 32C16.6133 32 17.2188 31.9656 17.8143 31.8979C18.3095 31.8427 18.7981 31.7637 19.2784 31.664C20.5471 31.3998 21.5 30.8827 22.6387 30.3377L23.905 29.5991L19.2778 29.611C18.8355 29.7173 18.3855 29.8027 17.9277 29.8657C17.8897 29.871 17.8517 29.8758 17.8137 29.8811C17.2194 29.9577 16.6145 29.9975 15.9994 29.9975C8.26894 29.9975 2.00193 23.7311 2.00193 16C2.00193 8.26894 8.26954 2.00312 16 2.00312C20.7828 2.00312 24.8733 4.21403 27.3977 7.87414L29.0482 6.73781C26.148 2.66033 21.3848 0 16 0Z"
                fill="#E30B00"
              />
              <path
                d="M25.0913 18.0117V28.9766H25.362C26.5269 28.1354 27.5729 27.1409 28.4724 26.023V23.6423H30.0605C30.9932 21.9301 31.6219 20.0297 31.8748 18.0117H25.0913ZM27.6816 21.4919V19.7049H30.3508V21.4919H27.6816Z"
                fill="#E30B00"
              />
              <path
                d="M25.8933 7.76403L23.1878 9.64366C21.4982 7.79728 19.0617 6.51253 16.0671 6.51253C14.9498 6.51253 13.877 6.70191 12.879 7.05041C12.8445 7.06288 12.8101 7.07475 12.7763 7.08722C9.05797 8.43312 6.4012 11.9953 6.4012 16.1784C6.4012 21.5175 10.7286 25.8449 16.0671 25.8449C16.6875 25.8449 17.2937 25.7867 17.8814 25.6751V28.9648C17.2883 29.0479 16.6828 29.0912 16.0671 29.0912C8.93567 29.0912 3.1543 23.3104 3.1543 16.1784C3.1543 9.0464 8.93567 3.26562 16.0671 3.26562C20.0656 3.26562 23.5429 4.93746 25.8933 7.76403Z"
                fill="black"
              />
              <path
                d="M31.9999 16.0002C31.9999 16.3766 31.9869 16.7494 31.9607 17.1193H23.9821L23.9548 21.4936L23.9251 26.1956L23.9168 27.5451L23.9079 28.9765H19.2391V17.1193H14.0437L19.7212 13.1914H31.7541C31.9156 14.1033 31.9999 15.0419 31.9999 16.0002Z"
                fill="#E30B00"
              />
              <path
                d="M31.0904 10.516L24.8317 12.4788H20.4431L26.7155 8.20419L29.0297 6.67188C29.8704 7.85095 30.5662 9.14104 31.0904 10.516Z"
                fill="#E30B00"
              />
            </g>
            <defs>
              <clipPath id="clip0_382_777">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
        <ul className="menu">
          <li className="menu__item">
            <Link className="menu__link header-main__link" to="/about">
              О нас
            </Link>
          </li>
          <li className="menu__item">
            <Link className="menu__link header-main__link" to="/services">
              Услуги
            </Link>
          </li>
          <li className="menu__item">
            <Link className="menu__link header-main__link" to="/news">
              Новости
            </Link>
          </li>
          <li className="menu__item">
            <Link className="menu__link header-main__link" to="/media">
              Медиа
            </Link>
          </li>
          <li className="menu__item">
            <Link className="menu__link header-main__link" to="/contacts">
              Контакты
            </Link>
          </li>
        </ul>
        <button className="button aboutintro_button" onClick={() => setFormActive(true)}>
          Связаться с нами
          <svg
            className="icon"
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928933C12.9526 0.538409 12.3195 0.538409 11.9289 0.928933C11.5384 1.31946 11.5384 1.95262 11.9289 2.34315L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM-8.74228e-08 9L19 9L19 7L8.74228e-08 7L-8.74228e-08 9Z"
              fill="white"
            />
          </svg>
        </button>
        <button className="header__burger-button" onClick={() => setModalActive(true)}>
          <svg
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2H20" stroke="black" stroke-width="2.29787" stroke-linecap="round" />
            <path d="M2 18H20" stroke="black" stroke-width="2.29787" stroke-linecap="round" />
            <path d="M2 10H20" stroke="black" stroke-width="2.29787" stroke-linecap="round" />
          </svg>
        </button>
      </header>

      <div className={modalActive ? 'header__burger-button-active' : 'burger-menu'}>
        <div className="burger-menu-container" onClick={(el) => el.stopPropagation()}>
          <button className="burger-menu-close" onClick={() => setModalActive(false)}>
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_360_47)">
                <path
                  d="M24.5416 8.27975L22.7203 6.4585L15.4999 13.6789L8.2795 6.4585L6.45825 8.27975L13.6787 15.5002L6.45825 22.7206L8.2795 24.5418L15.4999 17.3214L22.7203 24.5418L24.5416 22.7206L17.3212 15.5002L24.5416 8.27975Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_360_47">
                  <rect width="31" height="31" rx="15.5" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          <ul className="burger-menu-list">
            <li className="burger-menu-item">
              <Link className="burger-menu-link" to="/about">
                О нас
              </Link>
            </li>
            <li className="burger-menu-item">
              <Link className="burger-menu-link" to="/services">
                Услуги
              </Link>
            </li>
            <li className="burger-menu-item">
              <Link className="burger-menu-link" to="/news">
                Новости
              </Link>
            </li>
            <li className="burger-menu-item">
              <Link className="burger-menu-link" to="/media">
                Медиа
              </Link>
            </li>
            <li className="burger-menu-item">
              <Link className="burger-menu-link" to="/contacts">
                Контакты
              </Link>
            </li>
          </ul>
          <div className="burger-menu-contacts">
            <button className="button burger-button-contact" onClick={() => setFormActive(true)}>
              Связаться с нами
              <svg
                className="icon"
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928933C12.9526 0.538409 12.3195 0.538409 11.9289 0.928933C11.5384 1.31946 11.5384 1.95262 11.9289 2.34315L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM-8.74228e-08 9L19 9L19 7L8.74228e-08 7L-8.74228e-08 9Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <Form active={formActive} setActive={setFormActive} />
    </>
  );
};

export default HeaderMain;
