import React from 'react';
import HeaderMain from '../shared/HeaderMain';
import Footer from '../shared/Footer';
import Layout from '../shared/Layout';
import { Link } from 'react-router-dom';

import '../css/notfound.css';
import Cookie from '../shared/Cookie';

const NotFound = () => {
  window.scroll(0, 0);
  return (
    <>
      <Layout>
        <HeaderMain />
        <div className="not-found">
          <h2 className="not-found-title">Ошибка 404</h2>
          <p className="not-found-text">
            Кажется что-то пошло не так! Страница отсутствует либо просто не работает. Перейдите на
            нашу
            <Link className="not-found-link" to="/">
              главную страницу
            </Link>
            <br />и попробуйте найти необходимую информацию там.
          </p>
        </div>
      </Layout>
      <Footer />
      <Cookie />
    </>
  );
};

export default NotFound;
