import React from 'react';
import './services.css';
import '../Layout/layout.css';
import Form from '../Form';

import servicesBackground from '../../img/services.png';
import { Link } from 'react-router-dom';

const Services = () => {
  const [formActive, setFormActive] = React.useState(false);
  return (
    <div className="services">
      <div className="layout">
        <h2 className="services__title global-title">Услуги компании</h2>
        <h2 className="services__tagline">ВРЕЗКА ПОД ДАВЛЕНИЕМ</h2>
      </div>

      <div className="services__img-content">
        <div className="blur"></div>

        <img className="services__img" src={servicesBackground} alt="" />

        <div className="blur blur-two"></div>
      </div>
      <div className="absolute-block">
        <div className="layout services__content">
          <p className="services__text services__text-first">
            При невозможности остановки технологического оборудования или трубопровода на ремонт,
            врезка и перекрытие трубопровода под давлением является быстрым и безопасным способом
            обеспечения выполнения необходимых работ по реконструкции нефте- и газопроводов,
            подключению новых линий, а также осуществлению выборочного капитального ремонта
            без остановки транспорта продукта.
          </p>
          <div className="services__text-block">
            <p className="services__text">
              Врезка временного байпаса, лупинга и перекрытие полости трубы под давлением при
              ремонте трубопроводов с заменой или демонтажем дефектных участков труб, ЗРА, СДТ без
              остановки транспорта продукта
            </p>
            <div className="services__button-block">
              <button
                className="button contacts__button services-contact-button"
                onClick={() => setFormActive(true)}>
                Связаться с нами
                <svg
                  className="icon"
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928933C12.9526 0.538409 12.3195 0.538409 11.9289 0.928933C11.5384 1.31946 11.5384 1.95262 11.9289 2.34315L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM-8.74228e-08 9L19 9L19 7L8.74228e-08 7L-8.74228e-08 9Z"
                    fill="white"
                  />
                </svg>
              </button>
              <Link
                to="/tapping-under-pressure"
                className="button services__button services__button-white">
                Подробнее
                <svg
                  className="icon"
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928933C12.9526 0.538409 12.3195 0.538409 11.9289 0.928933C11.5384 1.31946 11.5384 1.95262 11.9289 2.34315L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM-8.74228e-08 9L19 9L19 7L8.74228e-08 7L-8.74228e-08 9Z"
                    fill="black"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Form active={formActive} setActive={setFormActive} />
    </div>
  );
};

export default Services;
