import React from 'react';
import './footer.css';

import politika from '../../img/politika.pdf';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__logo">
        <svg
          width="296"
          height="112"
          viewBox="0 0 296 112"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_57_847)">
            <path
              d="M129.856 63.6925C129.091 62.5767 128.709 61.0328 128.709 59.067V52.3678C128.709 50.292 129.085 48.7148 129.833 47.6364C130.582 46.558 131.91 46.0198 133.816 46.0198C135.518 46.0198 136.74 46.4811 137.482 47.4016C138.224 48.3242 138.594 49.6811 138.594 51.4764V52.9746H136.087V51.6406C136.087 50.7657 136.048 50.0946 135.971 49.625C135.892 49.1574 135.693 48.7813 135.372 48.5008C135.052 48.2203 134.541 48.079 133.839 48.079C133.136 48.079 132.585 48.2307 132.234 48.5361C131.883 48.8416 131.652 49.2634 131.544 49.8016C131.436 50.3398 131.38 51.0629 131.38 51.9689V59.4868C131.38 60.4551 131.457 61.2115 131.615 61.758C131.771 62.3044 132.024 62.6951 132.375 62.9299C132.727 63.1647 133.215 63.2811 133.839 63.2811C134.51 63.2811 135.011 63.1335 135.337 62.8364C135.666 62.5393 135.871 62.1341 135.957 61.6187C136.042 61.1034 136.085 60.4011 136.085 59.5096V58.105H138.592V59.5096C138.592 61.3985 138.237 62.8468 137.526 63.8546C136.815 64.8624 135.578 65.3652 133.812 65.3652C131.937 65.3652 130.617 64.8063 129.852 63.6904L129.856 63.6925Z"
              fill="#E30B00"
            />
            <path
              d="M141.697 63.786C140.893 62.7325 140.492 61.2052 140.492 59.2083V52.0167C140.492 50.0489 140.897 48.559 141.71 47.5429C142.522 46.5289 143.85 46.0198 145.692 46.0198C147.533 46.0198 148.857 46.5309 149.662 47.5533C150.466 48.5756 150.867 50.0634 150.867 52.0146V59.2291C150.867 61.1969 150.462 62.7117 149.649 63.7735C148.837 64.8354 147.517 65.3673 145.69 65.3673C143.863 65.3673 142.499 64.8395 141.695 63.786H141.697ZM147.683 62.4167C148.026 61.839 148.199 60.9642 148.199 59.7943V51.4806C148.199 50.3252 148.026 49.4712 147.683 48.9164C147.34 48.3616 146.675 48.0852 145.692 48.0852C144.709 48.0852 144.021 48.3637 143.676 48.9164C143.331 49.4691 143.16 50.3252 143.16 51.4806V59.7943C143.16 60.9663 143.331 61.839 143.676 62.4167C144.021 62.9943 144.69 63.2831 145.692 63.2831C146.694 63.2831 147.34 62.9943 147.683 62.4167Z"
              fill="white"
            />
            <path
              d="M153.189 46.186H157.547C159.266 46.186 160.538 46.5725 161.365 47.3455C162.193 48.1185 162.606 49.3175 162.606 50.9403C162.606 53.3611 161.7 54.6951 159.89 54.9466C161.014 55.2125 161.833 55.7382 162.348 56.5279C162.864 57.3175 163.122 58.3897 163.122 59.7486C163.122 61.4505 162.718 62.7782 161.916 63.7299C161.112 64.6816 159.89 65.1595 158.25 65.1595H153.189V46.1881V46.186ZM157.406 54.1029C158.499 54.1029 159.256 53.864 159.678 53.3881C160.1 52.9123 160.309 52.0956 160.309 50.9403C160.309 50.0967 160.181 49.4775 159.923 49.0785C159.665 48.6816 159.279 48.4281 158.763 48.318C158.248 48.2099 157.483 48.1538 156.466 48.1538H155.835V54.1029H157.404H157.406ZM157.616 63.2603C158.397 63.2603 159.002 63.1398 159.43 62.8967C159.861 62.6556 160.16 62.2837 160.332 61.785C160.503 61.2863 160.59 60.6068 160.59 59.7486C160.59 58.531 160.341 57.6208 159.84 57.0203C159.339 56.4198 158.528 56.1185 157.404 56.1185H155.835V63.2624H157.616V63.2603Z"
              fill="white"
            />
            <path
              d="M165.183 46.186H169.893C171.533 46.186 172.724 46.6307 173.466 47.5201C174.208 48.4094 174.578 49.7143 174.578 51.4307C174.578 52.9767 174.181 54.1798 173.383 55.038C172.587 55.8962 171.431 56.3263 169.916 56.3263H167.831V65.1575H165.183V46.186ZM169.049 54.4312C169.831 54.4312 170.436 54.346 170.864 54.1736C171.292 54.0011 171.602 53.704 171.789 53.2842C171.976 52.8624 172.069 52.2536 172.069 51.4577C172.069 50.4894 171.999 49.7788 171.859 49.3258C171.718 48.8728 171.442 48.5528 171.028 48.3658C170.614 48.1788 169.962 48.0853 169.072 48.0853H167.831V54.4333H169.049V54.4312Z"
              fill="white"
            />
            <path
              d="M176.147 46.186H183.505V48.1538H178.795V54.4312H182.613V56.3055H178.795V63.2624H183.551V65.1595H176.147V46.1881V46.186Z"
              fill="white"
            />
            <path
              d="M185.706 46.186H188.306L191.656 61.573L195.03 46.186H197.607L197.958 65.1575H195.826L195.616 51.385L192.407 65.1575H190.908L187.722 51.385L187.512 65.1575H185.357L185.708 46.186H185.706Z"
              fill="white"
            />
            <path
              d="M200.583 46.186H207.941V48.1538H203.231V54.4312H207.049V56.3055H203.231V63.2624H207.987V65.1595H200.583V46.1881V46.186Z"
              fill="white"
            />
            <path
              d="M209.836 46.186H212.484V54.3834H217.356V46.186H220.004V65.1575H217.356V56.2806H212.484V65.1575H209.836V46.186Z"
              fill="white"
            />
            <path
              d="M222.698 46.186H225.346V54.3834H230.218V46.186H232.866V65.1575H230.218V56.2806H225.346V65.1575H222.698V46.186Z"
              fill="white"
            />
            <path
              d="M235.559 46.186H238.207V54.0801H238.955C240.392 54.0801 241.545 54.2442 242.412 54.5725C243.278 54.9008 243.931 55.464 244.368 56.2598C244.804 57.0556 245.024 58.1715 245.024 59.6094C245.024 61.5627 244.548 62.9736 243.594 63.8484C242.643 64.7232 241.134 65.1595 239.074 65.1595H235.559V46.1881V46.186ZM239.074 63.2603C239.918 63.2603 240.585 63.1668 241.077 62.9798C241.57 62.7928 241.936 62.4333 242.179 61.9014C242.42 61.3715 242.543 60.5985 242.543 59.5824C242.543 58.7076 242.433 58.0136 242.214 57.4982C241.996 56.9829 241.632 56.6047 241.125 56.3616C240.618 56.1206 239.92 55.998 239.028 55.998H238.209V63.2582H239.076L239.074 63.2603ZM246.993 46.186H249.641V65.1575H246.993V46.186Z"
              fill="white"
            />
            <path
              d="M252.332 46.186H259.69V48.1538H254.98V54.4312H258.798V56.3055H254.98V63.2624H259.736V65.1595H252.332V46.1881V46.186Z"
              fill="white"
            />
            <path
              d="M131.145 71.574H127.936V69.6062H136.908V71.574H133.793V88.5776H131.145V71.574Z"
              fill="#E30B00"
            />
            <path
              d="M138.453 69.6062H145.81V71.574H141.101V77.8514H144.919V79.7257H141.101V86.6826H145.856V88.5797H138.453V69.6083V69.6062Z"
              fill="white"
            />
            <path
              d="M150.495 78.6472L146.606 69.6062H149.067L151.948 76.3054L154.571 69.6062H157.032L153.05 78.8571L157.219 88.5776H154.76L151.667 81.4337L148.997 88.5776H146.538L150.497 78.6472H150.495Z"
              fill="white"
            />
            <path
              d="M158.717 69.6062H161.365V77.8036H166.237V69.6062H168.885V88.5776H166.237V79.7007H161.365V88.5776H158.717V69.6062Z"
              fill="white"
            />
            <path
              d="M172.435 87.2084C171.631 86.1549 171.23 84.6276 171.23 82.6307V75.439C171.23 73.4712 171.635 71.9814 172.448 70.9653C173.26 69.9512 174.588 69.4421 176.43 69.4421C178.271 69.4421 179.595 69.9533 180.4 70.9756C181.204 71.998 181.605 73.4858 181.605 75.4369V82.6515C181.605 84.6193 181.2 86.1341 180.387 87.1959C179.575 88.2577 178.255 88.7897 176.428 88.7897C174.601 88.7897 173.237 88.2619 172.433 87.2084H172.435ZM178.421 85.839C178.764 85.2614 178.936 84.3866 178.936 83.2167V74.9029C178.936 73.7476 178.764 72.8936 178.421 72.3388C178.078 71.784 177.413 71.5076 176.43 71.5076C175.447 71.5076 174.759 71.786 174.414 72.3388C174.069 72.8915 173.898 73.7476 173.898 74.9029V83.2167C173.898 84.3886 174.069 85.2614 174.414 85.839C174.759 86.4167 175.428 86.7055 176.43 86.7055C177.432 86.7055 178.078 86.4167 178.421 85.839Z"
              fill="white"
            />
            <path
              d="M182.497 86.6098C183.168 86.6244 183.692 86.4997 184.066 86.2358C184.44 85.9698 184.754 85.3465 185.003 84.3615C185.253 83.3787 185.448 81.8244 185.589 79.7007L186.221 69.6062H194.562V88.5776H191.914V71.574H188.493L188.048 79.7007C187.924 81.8244 187.712 83.5117 187.416 84.7605C187.119 86.0093 186.635 86.9631 185.964 87.6176C185.292 88.2743 184.338 88.6005 183.106 88.6005C182.84 88.6005 182.638 88.5922 182.497 88.5776V86.6098Z"
              fill="white"
            />
            <path
              d="M198.112 87.2084C197.308 86.1549 196.907 84.6276 196.907 82.6307V75.439C196.907 73.4712 197.312 71.9814 198.125 70.9653C198.937 69.9512 200.265 69.4421 202.107 69.4421C203.948 69.4421 205.272 69.9533 206.077 70.9756C206.881 71.998 207.282 73.4858 207.282 75.4369V82.6515C207.282 84.6193 206.877 86.1341 206.064 87.1959C205.251 88.2577 203.932 88.7897 202.105 88.7897C200.278 88.7897 198.914 88.2619 198.11 87.2084H198.112ZM204.098 85.839C204.441 85.2614 204.613 84.3866 204.613 83.2167V74.9029C204.613 73.7476 204.441 72.8936 204.098 72.3388C203.755 71.784 203.09 71.5076 202.107 71.5076C201.124 71.5076 200.436 71.786 200.091 72.3388C199.746 72.8915 199.575 73.7476 199.575 74.9029V83.2167C199.575 84.3886 199.746 85.2614 200.091 85.839C200.436 86.4167 201.105 86.7055 202.107 86.7055C203.109 86.7055 203.755 86.4167 204.098 85.839Z"
              fill="white"
            />
            <path
              d="M209.602 69.6062H216.818V71.574H212.25V88.5776H209.602V69.6062Z"
              fill="white"
            />
            <path
              d="M218.362 69.6062H220.705V82.7449L226.256 69.6062H228.154V88.5776H225.834V75.227L220.328 88.5776H218.36V69.6062H218.362Z"
              fill="white"
            />
            <path
              d="M238.114 79.8193C237.083 80.0541 236.154 80.1705 235.326 80.1705C233.701 80.1705 232.496 79.8068 231.708 79.0816C230.92 78.3565 230.525 77.251 230.525 75.7674V69.6084H233.196V75.7674C233.196 76.69 233.398 77.3632 233.805 77.7933C234.212 78.2235 234.89 78.4375 235.844 78.4375C236.515 78.4375 237.274 78.344 238.116 78.157V69.6084H240.786V88.5798H238.116V79.8193H238.114Z"
              fill="white"
            />
            <path
              d="M243.453 69.6062H250.811V71.574H246.101V77.8514H249.919V79.7257H246.101V86.6826H250.856V88.5797H243.453V69.6083V69.6062Z"
              fill="white"
            />
            <path
              d="M253.504 87.1149C252.739 85.999 252.357 84.4551 252.357 82.4894V75.7902C252.357 73.7143 252.733 72.1372 253.481 71.0588C254.23 69.9803 255.558 69.4421 257.464 69.4421C259.166 69.4421 260.388 69.9034 261.13 70.824C261.872 71.7466 262.242 73.1034 262.242 74.8988V76.3969H259.735V75.0629C259.735 74.1881 259.696 73.5169 259.619 73.0473C259.54 72.5798 259.341 72.2037 259.02 71.9232C258.7 71.6427 258.189 71.5014 257.487 71.5014C256.784 71.5014 256.233 71.653 255.882 71.9585C255.531 72.264 255.3 72.6858 255.192 73.224C255.084 73.7621 255.028 74.4853 255.028 75.3912V82.9092C255.028 83.8775 255.105 84.6338 255.263 85.1803C255.419 85.7268 255.672 86.1175 256.023 86.3523C256.375 86.5871 256.863 86.7034 257.487 86.7034C258.158 86.7034 258.659 86.5559 258.985 86.2588C259.314 85.9616 259.519 85.5564 259.605 85.0411C259.69 84.5258 259.733 83.8234 259.733 82.932V81.5273H262.24V82.932C262.24 84.8208 261.885 86.2691 261.174 87.2769C260.463 88.2847 259.226 88.7876 257.46 88.7876C255.585 88.7876 254.265 88.2286 253.5 87.1128L253.504 87.1149Z"
              fill="white"
            />
            <path
              d="M264.491 69.6063H267.139V78.0385H268.122L271.518 69.6063H274.166L270.419 78.6224L274.636 88.5757H271.988L268.145 79.3248H267.137V88.5757H264.489V69.6042L264.491 69.6063Z"
              fill="white"
            />
            <path
              d="M276.111 69.6062H278.454V82.7449L284.005 69.6062H285.903V88.5776H283.583V75.227L278.078 88.5776H276.109V69.6062H276.111Z"
              fill="white"
            />
            <path
              d="M288.597 69.6062H295.954V71.574H291.245V77.8514H295.063V79.7257H291.245V86.6826H296V88.5797H288.597V69.6083V69.6062Z"
              fill="white"
            />
            <path
              d="M129.058 93.0286H133.768C135.408 93.0286 136.599 93.4732 137.341 94.3626C138.083 95.2519 138.453 96.5569 138.453 98.2732C138.453 99.8192 138.056 101.022 137.258 101.881C136.462 102.739 135.306 103.169 133.791 103.169H131.706V112H129.058V93.0286ZM132.924 101.274C133.706 101.274 134.31 101.189 134.739 101.016C135.167 100.844 135.476 100.546 135.663 100.127C135.851 99.7049 135.944 99.0961 135.944 98.3003C135.944 97.3319 135.873 96.6213 135.734 96.1683C135.593 95.7153 135.316 95.3953 134.903 95.2083C134.489 95.0213 133.837 94.9278 132.947 94.9278H131.706V101.276H132.924V101.274Z"
              fill="#E30B00"
            />
            <path
              d="M140.022 93.0286H147.38V94.9964H142.67V101.274H146.488V103.148H142.67V110.105H147.425V112.002H140.022V93.0306V93.0286Z"
              fill="white"
            />
            <path
              d="M149.277 93.0286H151.925V110.08H155.346V93.0286H157.994V110.08H161.251V93.0286H163.899V112H149.279V93.0286H149.277Z"
              fill="white"
            />
            <path
              d="M166.566 93.0286H173.923V94.9964H169.214V101.274H173.032V103.148H169.214V110.105H173.969V112.002H166.566V93.0306V93.0286Z"
              fill="white"
            />
            <path
              d="M175.819 93.0286H178.467V101.226H183.339V93.0286H185.986V112H183.339V103.123H178.467V112H175.819V93.0286Z"
              fill="white"
            />
            <path
              d="M188.68 93.0286H191.023V106.167L196.574 93.0286H198.472V112H196.152V98.6493L190.646 112H188.678V93.0286H188.68Z"
              fill="white"
            />
            <path
              d="M203.979 103.474C203.104 103.084 202.423 102.452 201.93 101.577C201.438 100.702 201.192 99.601 201.192 98.2753C201.192 94.7782 203.175 93.0286 207.143 93.0286H210.986V112H208.338V104.131H206.839L206.23 104.108L202.951 112.002H199.999L203.982 103.476L203.979 103.474ZM208.338 102.234V94.9257H207.284C206.066 94.9257 205.164 95.1875 204.578 95.7112C203.992 96.2348 203.699 97.0826 203.699 98.2525C203.699 99.626 203.979 100.634 204.543 101.274C205.106 101.914 206.041 102.234 207.355 102.234H208.338Z"
              fill="white"
            />
            <path
              d="M128.863 0.0415039H130.717V10.4436L135.115 0.0415039H136.617V15.0607H134.782V4.49241L130.424 15.0607H128.867V0.0415039H128.863Z"
              fill="#EB0501"
            />
            <path
              d="M138.748 0.0415039H140.843V6.53085H144.7V0.0415039H146.796V15.0607H144.7V8.03319H140.843V15.0607H138.748V0.0415039Z"
              fill="white"
            />
            <path
              d="M151.322 7.38488L148.188 0.0415039H150.21L153.029 7.29137H153.141V0.0415039H154.922V7.29137H155.053L157.851 0.0415039H159.892L156.739 7.38488L160.243 15.0607H158.185L155.07 7.55111H154.92V15.0607H153.139V7.55111H153.008L149.874 15.0607H147.835L151.32 7.38488H151.322Z"
              fill="white"
            />
            <path
              d="M161.245 0.0415039H167.069V1.59787H163.342V6.56826H166.364V8.05189H163.342V13.5584H167.106V15.0607H161.245V0.0415039Z"
              fill="white"
            />
            <path
              d="M168.571 0.0415039H170.666V6.53085H174.524V0.0415039H176.619V15.0607H174.524V8.03319H170.666V15.0607H168.571V0.0415039Z"
              fill="white"
            />
            <path
              d="M178.754 0.0415039H184.577V1.59787H180.851V6.56826H183.873V8.05189H180.851V13.5584H184.615V15.0607H178.754V0.0415039Z"
              fill="white"
            />
            <path
              d="M186.078 0.0415039H189.807C191.106 0.0415039 192.047 0.394751 192.635 1.09917C193.222 1.80358 193.517 2.83631 193.517 4.19527C193.517 5.41917 193.201 6.37085 192.571 7.05033C191.941 7.72982 191.025 8.07059 189.825 8.07059H188.175V15.0607H186.08V0.0415039H186.078ZM189.139 6.56826C189.757 6.56826 190.237 6.49969 190.576 6.36462C190.917 6.22956 191.16 5.99475 191.309 5.6602C191.459 5.32566 191.532 4.84358 191.532 4.21397C191.532 3.44722 191.476 2.8841 191.365 2.5267C191.253 2.16722 191.035 1.91579 190.707 1.76618C190.378 1.61865 189.863 1.54384 189.158 1.54384H188.175V6.56826H189.139Z"
              fill="white"
            />
            <path
              d="M194.757 0.0415039H196.853V6.53085H200.71V0.0415039H202.805V15.0607H200.71V8.03319H196.853V15.0607H194.757V0.0415039Z"
              fill="white"
            />
            <path
              d="M207.313 0.0415039H209.336L212.545 15.0607H210.56L209.874 11.2768H206.814L206.091 15.0607H204.125L207.313 0.0415039ZM209.593 9.77657L208.332 3.00878L207.089 9.77657H209.593Z"
              fill="white"
            />
            <path
              d="M216.068 8.31163C215.376 8.0041 214.835 7.50332 214.444 6.8093C214.056 6.11735 213.86 5.2467 213.86 4.19527C213.86 1.42748 215.43 0.0415039 218.572 0.0415039H221.615V15.0607H219.52V8.83111H218.333L217.851 8.81241L215.255 15.0607H212.919L216.072 8.31163H216.068ZM219.518 7.32878V1.54384H218.682C217.718 1.54384 217.003 1.75163 216.539 2.16514C216.076 2.57865 215.843 3.24982 215.843 4.17657C215.843 5.2654 216.066 6.06124 216.512 6.56826C216.957 7.07527 217.699 7.32878 218.738 7.32878H219.518Z"
              fill="white"
            />
            <path
              d="M128.863 20.0479H130.958V26.7221H131.737L134.427 20.0479H136.522L133.554 27.1876L136.892 35.0671H134.797L131.756 27.7424H130.958V35.0671H128.863V20.0479Z"
              fill="#E30B00"
            />
            <path
              d="M138.74 33.9823C138.101 33.147 137.783 31.9397 137.783 30.3563V24.6649C137.783 23.1065 138.106 21.9262 138.748 21.1241C139.39 20.32 140.442 19.9189 141.901 19.9189C143.36 19.9189 144.407 20.3241 145.043 21.1345C145.679 21.9449 145.999 23.121 145.999 24.667V30.3771C145.999 31.9335 145.677 33.1345 145.035 33.974C144.393 34.8135 143.347 35.2353 141.901 35.2353C140.454 35.2353 139.376 34.8176 138.74 33.9844V33.9823ZM143.478 32.8976C143.751 32.4405 143.886 31.7486 143.886 30.8218V24.2389C143.886 23.3247 143.751 22.6473 143.478 22.2088C143.206 21.7704 142.68 21.5501 141.901 21.5501C141.121 21.5501 140.577 21.7704 140.305 22.2088C140.032 22.6473 139.897 23.3247 139.897 24.2389V30.8218C139.897 31.7486 140.034 32.4405 140.305 32.8976C140.575 33.3548 141.109 33.5834 141.901 33.5834C142.693 33.5834 143.204 33.3548 143.478 32.8976Z"
              fill="white"
            />
            <path
              d="M148.078 20.0479H150.136L152.788 32.2307L155.458 20.0479H157.499L157.778 35.0671H156.09L155.924 24.1642L153.382 35.0671H152.195L149.672 24.1642L149.506 35.0671H147.799L148.078 20.0479Z"
              fill="white"
            />
            <path
              d="M159.854 20.0479H168.127V35.0671H166.031V21.5502H161.952V35.0671H159.856V20.0479H159.854Z"
              fill="white"
            />
            <path
              d="M172.614 20.0479H174.636L177.845 35.0671H175.86L175.174 31.2832H172.115L171.392 35.0671H169.426L172.616 20.0479H172.614ZM174.894 29.7829L173.632 23.0151L172.389 29.7829H174.894Z"
              fill="white"
            />
            <path
              d="M179.142 20.0479H181.237V26.5372H185.095V20.0479H187.19V35.0671H185.095V28.0395H181.237V35.0671H179.142V20.0479Z"
              fill="white"
            />
            <path
              d="M189.324 20.0479H191.178V30.4499L195.574 20.0479H197.077V35.0671H195.242V24.4988L190.883 35.0671H189.327V20.0479H189.324Z"
              fill="white"
            />
            <path
              d="M201.435 28.318C200.743 28.0104 200.203 27.5097 199.812 26.8156C199.424 26.1237 199.228 25.253 199.228 24.2016C199.228 21.4338 200.797 20.0479 203.94 20.0479H206.983V35.0671H204.888V28.8375H203.701L203.219 28.8188L200.623 35.0671H198.287L201.44 28.318H201.435ZM204.886 27.3351V21.5502H204.05C203.086 21.5502 202.371 21.758 201.907 22.1715C201.444 22.585 201.211 23.2562 201.211 24.1829C201.211 25.2717 201.433 26.0676 201.88 26.5746C202.325 27.0816 203.067 27.3351 204.106 27.3351H204.886Z"
              fill="white"
            />
            <path
              d="M111.167 46.1693H79.2257L60.8045 59.9169H67.3537V101.417H83.6985L83.7297 96.4073L83.7588 91.6842L83.8627 75.227L83.9583 59.9169H111.89C111.982 58.6223 112.028 57.3174 112.028 56C112.028 52.6462 111.732 49.361 111.167 46.1693ZM56.0138 0C25.0784 0 0 25.0722 0 56C0 86.9278 25.0784 112 56.0138 112C58.1608 112 60.2808 111.879 62.3655 111.643C64.0989 111.449 65.8094 111.173 67.4909 110.824C71.9325 109.899 75.2684 108.089 79.2548 106.182L83.6881 103.597L67.4888 103.638C65.9404 104.01 64.3649 104.31 62.7624 104.53C62.6294 104.549 62.4964 104.565 62.3634 104.584C60.2829 104.852 58.1649 104.991 56.0117 104.991C28.9484 104.991 7.00848 83.0587 7.00848 56C7.00848 28.9413 28.9505 7.01091 56.0138 7.01091C72.7576 7.01091 87.078 14.7491 95.9155 27.5595L101.694 23.5823C91.5404 9.31117 74.8651 0 56.0138 0ZM56.0138 10.8052C31.0476 10.8052 10.8078 31.04 10.8078 56C10.8078 80.96 31.0476 101.195 56.0138 101.195C58.1691 101.195 60.2891 101.043 62.3655 100.752V89.2384C60.3078 89.6291 58.1857 89.8327 56.0138 89.8327C37.3245 89.8327 22.1748 74.6868 22.1748 56C22.1748 41.359 31.4758 28.8914 44.493 24.1808C44.6115 24.1371 44.732 24.0956 44.8526 24.0519C48.3464 22.8322 52.1022 22.1694 56.0138 22.1694C68.0832 22.1694 74.9711 23.3953 80.9633 32.9018L90.4139 26.5496C80.7035 16.2473 69.5568 10.8052 56.0138 10.8052ZM87.8408 63.04V101.417H88.7886C92.8665 98.4727 96.5286 94.9922 99.6775 91.0795V82.747H105.237C108.503 76.7543 110.704 70.1029 111.589 63.04H87.8408ZM96.909 75.2208V68.9662H106.254V75.2208H96.909Z"
              fill="#E30B00"
            />
            <path
              d="M90.4139 26.5496L80.9425 33.1283C75.0273 26.666 66.4974 22.1693 56.0138 22.1693C52.1022 22.1693 48.3464 22.8322 44.8526 24.0519C44.732 24.0956 44.6115 24.1371 44.493 24.1808C31.4758 28.8914 22.1748 41.3589 22.1748 56C22.1748 74.6867 37.3245 89.8327 56.0138 89.8327C58.1857 89.8327 60.3078 89.6291 62.3655 89.2384V100.752C60.2891 101.043 58.1691 101.195 56.0138 101.195C31.0476 101.195 10.8079 80.9621 10.8079 56C10.8079 31.0379 31.0476 10.8052 56.0138 10.8052C70.012 10.8052 82.1854 16.6566 90.4139 26.5496Z"
              fill="white"
            />
            <path
              d="M92.8976 29.8349L83.4491 36.1871C83.4117 36.1372 83.3763 36.0873 83.3369 36.0375L92.6919 29.5398C92.7605 29.6375 92.8291 29.7351 92.8976 29.8349Z"
              fill="black"
            />
            <path
              d="M112.028 56.0001C112.028 57.3175 111.982 58.6224 111.89 59.917H83.9583L83.8627 75.2271L83.7588 91.6842L83.7297 96.4074L83.6985 101.417H67.3537V59.917H49.1653L69.0414 46.1694H111.167C111.732 49.3611 112.028 52.6463 112.028 56.0001Z"
              fill="#E30B00"
            />
            <path
              d="M108.843 36.8064L86.9325 43.676H71.5687L93.5274 28.7149L101.629 23.3518C104.572 27.4786 107.008 31.9939 108.843 36.8064Z"
              fill="#E30B00"
            />
          </g>
          <defs>
            <clipPath id="clip0_57_847">
              <rect width="296" height="112" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="footer__contact">
        <p>Тел: +7 (495) 128-89-97</p>
        <p>E-mail: info@ikstr.ru</p>
        <a href={politika} target="_blank">
          Политика конфиденциальности
        </a>
      </div>
    </div>
  );
};

export default Footer;
