import React from 'react'
import Layout from '../shared/Layout'
import HeaderMain from '../shared/HeaderMain'
import Footer from '../shared/Footer'
import Cookie from '../shared/Cookie'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import newOneIntro from '../img/news-one-intro.png'

import newThree1 from '../img/news-back-three-1.webp'
import newThree2 from '../img/news-back-three-2.webp'
import newThree3 from '../img/news-back-three-3.webp'
import newThree4 from '../img/news-back-three-4.webp'
import newThree5 from '../img/news-back-three-5.webp'

const NewThree = () => {
   window.scroll(0, 0)

   const responsive = { all: { breakpoint: { max: 4000, min: 0 }, items: 1, partialVisibilityGutter: 0 } }

   return (
      <>
         <Layout>
            <HeaderMain />
            <h2 className='news-block__title global-title page-title'>Новости</h2>
            <div className='new-one'>
               <h2 className='new-one-title'>Успешная периодическая аттестация по требованиям НАКС</h2>
               <Carousel responsive={responsive} centerMode={false} containerClass='new-one-slider' infinite={true}>
                  <div className='new-one-slide'>
                     <img src={newThree1} alt='Первый слайд' />
                  </div>
                  <div className='new-one-slide'>
                     <img src={newThree2} alt='Второй слайд' />
                  </div>
                  <div className='new-one-slide'>
                     <img src={newThree3} alt='Третий слайд' />
                  </div>
                  <div className='new-one-slide'>
                     <img src={newThree4} alt='Четвертый слайд' />
                  </div>
                  <div className='new-one-slide'>
                     <img src={newThree5} alt='Пятый слайд' />
                  </div>
               </Carousel>
               {/* <p className='new-one-text'>
                  В мае 2023 года в рамках реализации долгосрочной программы развития компании ООО ИК «Современные Технологические Решения»
                  выполнена аттестация сварщиков и технологии сварки
               </p> */}
               <p className='new-one-text'>
                  На производственной площадке в г. Екатеринбурге компания ООО ИК «СТР» с 05 по 08 ноября 2024 года успешно проведена
                  периодическая аттестация сварщиков и проверка готовности к выполнению сварочных работ в соответствии с требованиями
                  "Положения об аттестации на объектах ПАО «Газпром».
               </p>
               <p className='new-one-text'>
                  Все участники аттестации продемонстрировали высокое соответствие стандартам, включая СТО Газпром 2-2.3-116-2016, СТО
                  НОВАТЭК 001-2022, СТО Газпром 15-1.1-002-2023, СТО Газпром 15-1.2-003-2023 и СТО Газпром 2-2.2-649-2012, подтвердив свои
                  навыки в сварке различных материалов.
               </p>
               <p className='new-one-text'>
                  Аттестационный центр отметил высокий уровень квалификации сотрудников, их знание технологий и отличную организацию
                  рабочего процесса, а также соблюдение норм безопасности.
               </p>
               <p className='new-one-text'>
                  Эти результаты подчеркивают, что ООО ИК «СТР» готово предоставлять услуги сварки на высоком уровне, гарантируя качество и
                  надежность выполняемых работ.
               </p>
            </div>
         </Layout>
         <Footer />
         <Cookie />
      </>
   )
}

export default NewThree
