import React from 'react';
import './mission.css';

import missionBackground from '../../img/mission.png';

const Mission = () => {
  return (
    <div className="mission">
      <h2 className="mission__title global-title">Миссия</h2>
      <h2 className="mission__tagline">
        <span>Наша миссия — гарантировать нашим клиетам </span>
        <span>
          высококачественную услугу по технологии врезки и перекрытию трубопроводов под давлением,
          обеспечив надёжность, безопасность, скорость и экономичность при выполнении работ
        </span>
      </h2>
      <div className="mission__content">
        <div class="img-wrapper">
          <img className="mission__img" src={missionBackground} alt="" />
        </div>

        <p className="mission__text">
          Мы стремимся быть лидерами в нефтегазовой отрасли и для этого собрали команду
          высококвалифицированных и аттестованных специалистов, имеющих большой практический опыт
          выполнения работ по врезкам и перекрытию трубопроводов под давлением на объектах
          топливно-энергетического комплекса - более 300 успешно реализованных проектов.
        </p>
      </div>
    </div>
  );
};

export default Mission;
