import React from 'react';

import HeaderMain from '../shared/HeaderMain';

import Layout from '../shared/Layout';
import Footer from '../shared/Footer';
import NewsMain from '../shared/NewsMain';
import Cookie from '../shared/Cookie';

const News = () => {
  window.scroll(0, 0);
  return (
    <>
      <Layout>
        <HeaderMain />
        <NewsMain />
      </Layout>
      <Footer />
      <Cookie />
    </>
  );
};

export default News;
