import React from 'react';
import './cookie.css';

import politika from '../../img/politika.pdf';

const Cookie = () => {
  const [cookie, setNotActiveCookie] = React.useState(true);
  return (
    <>
      {cookie && (
        <div className="cookie">
          <p className="cookie__text">
            Продолжая находиться на ресурсе, вы соглашаетесь с{' '}
            <a href={politika} target="_blank" className="cookie__link">
              политикой сбора cookie
            </a>
          </p>
          <button className="cookie__button" onClick={() => setNotActiveCookie(false)}>
            Принять
            <svg
              width="7"
              height="13"
              viewBox="0 0 7 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 12L5.76667 6.5"
                stroke="#FF0000"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path d="M1 1L5.76667 6.5" stroke="#FF0000" stroke-width="2" stroke-linecap="round" />
            </svg>
          </button>
        </div>
      )}
    </>
  );
};

export default Cookie;
