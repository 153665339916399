import React from 'react';
import './form.css';

const Form = ({ active, setActive }) => {
  return (
    <div className={active ? 'form' : 'form-not-active'} onClick={() => setActive(false)}>
      <form className="form-content" action="" onClick={(el) => el.stopPropagation()}>
        <h2 className="form-title">Связаться с нами</h2>
        <input className="form-input" id="name" type="text" placeholder="ФИО*" />
        <input className="form-input" id="phone" type="text" placeholder="Телефон*" />
        <input className="form-input" id="email" type="text" placeholder="E-mail" />
        <input className="form-input" id="text" type="text" placeholder="Сooбщение" />
        <div className="form-checkbox">
          <input type="checkbox" id="checkbox" />
          <label htmlFor="checkbox">
            Соглашаюсь с политикой в области обработки персональных данных
          </label>
        </div>
        <input id="button" className="form-btn" type="submit" value="Отправить заявку" />
        <button className="form-btn-close" onClick={() => setActive(false)}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M23.6663 2.68325L21.3163 0.333252L11.9997 9.64992L2.68301 0.333252L0.333008 2.68325L9.64967 11.9999L0.333008 21.3166L2.68301 23.6666L11.9997 14.3499L21.3163 23.6666L23.6663 21.3166L14.3497 11.9999L23.6663 2.68325Z"
              fill="#21242E"
              fill-opacity="0.4"
            />
          </svg>
        </button>
      </form>
    </div>
  );
};

export default Form;
