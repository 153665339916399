import React from 'react';
import './presentation.css';

import presentation from '../../img/presentation.png';
import presentationPdf from '../../img/ikstr.pptx';

import list from '../../img/list.png';
import listPdf from '../../img/list.pdf';

const Presentation = () => {
  return (
    <div className=" presentation__container">
      <div className="presentation">
        <img className="presentation__img" src={presentation} alt="Изображение презентации" />
        <a className="presentation__link" href={presentationPdf}>
          Скачать
          <svg
            width="21"
            height="23"
            viewBox="0 0 21 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.1805 1.35547L10.1805 17.3622"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M3.91699 11.1016L10.1805 17.3651"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M16.444 11.1016L10.1805 17.3651"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M1.38965 21.5781H19.0813"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M1.38965 15.6797L1.38965 21.5769"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M19.0813 15.6797L19.0813 21.5769"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </a>
      </div>
      <div className="presentation">
        <img className="presentation__img" src={list} alt="Изображение опросного листа" />
        <a className="presentation__link" href={listPdf}>
          Скачать
          <svg
            width="21"
            height="23"
            viewBox="0 0 21 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.1805 1.35547L10.1805 17.3622"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M3.91699 11.1016L10.1805 17.3651"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M16.444 11.1016L10.1805 17.3651"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M1.38965 21.5781H19.0813"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M1.38965 15.6797L1.38965 21.5769"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M19.0813 15.6797L19.0813 21.5769"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </a>
      </div>
    </div>
  );
};

export default Presentation;
